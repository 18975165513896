import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HitPay from "../Page/hitPay";
import Dashboard from "../Page/Dashboard";
import { getCompanyData } from "../utils";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Success from "../Page/success";

export default function Customer() {
  // const navigate = useNavigate()

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const type = queryParams.get('type');
  // const reference = queryParams.get('reference');
  // const status = queryParams.get('status');
  // console.log(status, type, reference);

  // useEffect(() => {
  //     if (type && reference && status) {

  //         console.log(`Type: ${type}, Reference: ${reference}, Status: ${status}`);
  //         if (status == 'active') {
  //             console.log('hgc');
  //             localStorage.setItem("company_details[payment_status]", 1)
  //             navigate('/Dashboard');

  //         }

  //     } else {
  //         console.error('Missing required parameters');
  //     }
  // }, [type, reference, status, navigate]);
  // let data = JSON.parse(localStorage.getItem('company_details'))
  // data.payment_status = 0
  // localStorage.setItem("company_details", JSON.stringify(data))

  return (
    <Routes>
      {/* <Route path='/p' element={<HitPay />} /> */}
      {
        getCompanyData("payment_status") == 0 && (
          <>
            <Route path="/pay" element={<HitPay />} />
            <Route path="/Customer" element={<Success />} />
          </>
        )
        // :
        // <Route path='/Dashboard' element={<Dashboard />}>

        // </Route>
      }
      {getCompanyData("payment_status") == 1 && (
        <Route path="/Dashboard" element={<Dashboard />}></Route>
      )}
    </Routes>
  );
}
