import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Paper,
  Stack,
  Typography,
  Box,
  Button,
  PaginationItem,
  Pagination,
  OutlinedInput,
} from "@mui/material";
import {
  PrimaryTypography,
  secondaryTypography,
  StyledTableCell,
} from "../../Theme/theme";
import axiosInstance from "../../Service/apiservice";
import AlertModal from "../../Component/modal";
import moment from "moment/moment";

const SubscriptionDetails = ({ id }) => {
  const [Subdetails, setSubDetails] = useState({});
  const [SubTableData, setSubTableData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_WMS_API_BASE_URL}/company/${id}/current_plan`
      )
      .then((res) => {
        setSubDetails(res.data?.data?.company);
      })
      .catch((err) => {
        console.error(err);
      });

  }, []);

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  const getTableData = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_WMS_API_BASE_URL}/company/${id}/plan_log`)
      .then((res) => {
        setSubTableData(res.data?.data?.company);
        setPaginationData(res.data?.data?.pagination_details);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          // padding: "0px 20px 0px 20px",
          marginBottom: "20px",
        }}
        component={Paper}
      >
        <Box
          sx={{
            // borderRadius: "8px 0px 0px 8px",
            border: "0.5px solid #E5E7EB",
            padding: "20px",
            backgroundColor: "#FFFFFF",
            width: "33.3%",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", fontWeight: 500, marginBottom: "8px" }}
          >
            Current Plan
          </Typography>
          <Typography>{Subdetails?.name || "--"}</Typography>
        </Box>
        <Box
          sx={{
            border: "0.5px solid #E5E7EB",
            padding: "20px",
            backgroundColor: "#FFFFFF",
            width: "33.3%",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", fontWeight: 500, marginBottom: "8px" }}
          >
            Validity Starts
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>
              {Subdetails?.first_cycle
                ? moment(Subdetails.first_cycle).format("DD MMM YYYY")
                : "--"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            // borderRadius: "0px 8px 8px 0px",
            border: "0.5px solid #E5E7EB",
            padding: "20px",
            backgroundColor: "#FFFFFF",
            width: "33.4%",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              marginBottom: "8px",
              color: "#000000DD",
            }}
          >
            Validity Ends
          </Typography>
          <Typography>
            {Subdetails?.next_cycle
              ? moment(Subdetails.next_cycle).format("DD MMM YYYY")
              : "--"}
          </Typography>
        </Box>
      </Box>

      <PrimaryTypography>Plan Logs</PrimaryTypography>

      <TableContainer
        sx={{ borderRadius: "12px", marginTop: "30px" }}
        component={Paper}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>No.</StyledTableCell>
              <StyledTableCell>Plan Name</StyledTableCell>
              <StyledTableCell>From Date</StyledTableCell>
              <StyledTableCell>To Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {SubTableData?.map((itm, index) => {
              return (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>
                    {itm?.plan_details?.name || "--"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {itm?.from_date
                      ? moment(itm.from_date).format("DD MMM YYYY  , hh:mm")
                      : "--"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {itm?.to_date
                      ? moment(itm.to_date).format("DD MMM YYYY , hh:mm")
                      : "--"}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #E5E7EB",
            borderRadius: "0px 0px 8px 8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            p: 2.5,
          }}
        >
          <Pagination
            page={currentPage}
            size="large"
            count={Math.ceil(paginationData?.total / paginationData?.per_page)}
            onChange={(e, value) => setCurrentPage(value)}
            renderItem={(item) => (
              <PaginationItem
                // slots={{ previous: PreviousIcon, next: NextIcon }}
                {...item}
              />
            )}
          />
        </Box>
      </TableContainer>
    </>
  );
};

export default SubscriptionDetails;
