import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch } from "react-redux";

const initialState = {
    loading: false,
    loginStatus: null,

}

export const login = createAsyncThunk('login', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_WMS_API_BASE_URL}/login`, data);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

const Login = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLoginSliceToNull: (state) => {
            state.loginStatus = null
        },

    },
    extraReducers: (builders) => {
        //login
        builders.addCase(login.fulfilled, (state, action) => {
            state.loginStatus = action.payload.data
        })
        builders.addCase(login.rejected, (state, action) => {
            state.loginStatus = action?.payload?.response?.data
        })

    }
})

export const { setLoginSliceToNull } = Login.actions
export default Login.reducer