import { PostAdd } from '@mui/icons-material';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorTypography, PrimaryTypography } from '../Theme/theme';
import { Paper, Stack, Typography, Box, Button, MenuItem, OutlinedInput, Grid, Autocomplete, Select, IconButton, Chip, TextField } from '@mui/material';
import logo from '../Image/logo.png';
import { getUserData } from '../utils';
import axiosInstance from '../Service/apiservice';


const PaymentComponent = () => {

    const navigate = useNavigate()
    const [disablebtn, setDisablebtn] = useState(false)
    const [resErr, setResErr] = useState(null)

    const paymentRequst = async () => {
        setDisablebtn(true)
        try {
            console.log(window.location.origin);
            const response = await axiosInstance.get(`${process.env.REACT_APP_WMS_API_BASE_URL}/payment/request?redirect_url=${window.location.origin}/Customer`)
            console.log(response);
            window.location.href = response?.data?.data?.payment_request?.url
            setDisablebtn(false)


        } catch (error) {
            setDisablebtn(false)
            console.log('payment', error);
            setResErr(error?.message)
        }
    }




    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', width: '100vw', height: "90vh", textAlign: 'center' }} >
            <img src={logo} wdith='100px' height='100px' />
            <PrimaryTypography my={3}>Hi {getUserData('name')},  Please complete your payment <br /> to activate your account</PrimaryTypography>
            <Button variant='contained' disabled={disablebtn} onClick={() => paymentRequst()}>Pay now</Button>
            <ErrorTypography mt={3} sx={{ color: '#cb3131 !important',fontSize:'1.2rem !important' }}>{resErr}</ErrorTypography>
        </Box>
    );
};

export default PaymentComponent;
