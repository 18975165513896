import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Paper,
  Stack,
  Typography,
  Box,
  Button,
  MenuItem,
  OutlinedInput,
  Grid,
  Autocomplete,
  Select,
  IconButton,
  Chip,
  TextField,
} from "@mui/material";
import {
  SecondaryTypography,
  ErrorTypography,
  PrimaryTypography,
} from "../../Theme/theme";
import axiosInstance from "../../Service/apiservice";
import { Navigate } from "react-router-dom";

export default function EditSubscriptionPlan() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [daymonthyearDD, setdaymonthyearDD] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [data, setData] = useState({
    name: "",
    amount: "",
    plan_cycle: "",
    duration: "",
    description: "",
    feature: [],
  });
  const [errText, setErrText] = useState({
    name: "",
    amount: "",
    plan_cycle: "",
    duration: "",
  });
  const [featuresData, setFeaturesData] = useState([]);

  const getPlan = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_WMS_API_BASE_URL}/subscription_plan/${id}`
      );
      console.log(response);
      const value = response?.data?.data?.subscription_plan;
      const feature = value?.feature.find((itm) => itm?.is_enabled == true);
      setData({
        name: value?.name,
        amount: value?.amount,
        plan_cycle: value?.day_month_year,
        duration: value?.duration,
        description: value?.description,
        feature: [feature],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const DaymonthyearDD = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_WMS_API_BASE_URL}/dropdown?type=day_month_year`
      );
      console.log(response);
      setdaymonthyearDD(response?.data?.data?.day_month_year);
    } catch (error) {
      console.log("dMYdropdown:", error);
    }
  };

  const FeatureDD = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_WMS_API_BASE_URL}/dropdown?type=plan_feature`
      );
      console.log(response);
      setFeaturesData(response?.data?.data?.plan_feature);
    } catch (error) {
      console.log("featuredropdown:", error);
    }
  };

  useEffect(() => {
    DaymonthyearDD();
    FeatureDD();
    getPlan();
  }, []);

  const isValidInput = (value) => {
    const regex = /^(?!.*\..*\.)[0-9]*(\.[0-9]{0,2})?$/;
    return regex.test(value);
  };

  const handleChange = (e, featureval) => {
    const { name, value } = e.target;

    if (name == "amount") {
      if (isValidInput(value)) {
        setData((prev) => ({ ...prev, [name]: value }));
        setErrText((prev) => ({ ...prev, [name]: "" }));
      }
    } else {
      if (Boolean(featureval)) {
        setData((prev) => ({ ...prev, feature: featureval }));
      } else {
        setData((prev) => ({ ...prev, [name]: value }));
      }

      if (name != "description" && name != "feature") {
        setErrText((prev) => ({ ...prev, [name]: "" }));
      }
    }
  };

  const validate = () => {
    let isError = false;
    for (const key in errText) {
      if (data[key] == "") {
        setErrText((prev) => ({ ...prev, [key]: "Required field !" }));
        isError = true;
      }
    }
    return isError;
  };
  const submit = () => {
    if (!validate()) {
      setDisableBtn(true);
      EditPlans();
    }
  };

  const EditPlans = async () => {
    const featureId = data?.feature.map((itm) => itm?.id);

    const fdata = {
      name: data?.name,
      description: data?.description,
      duration: data?.duration,
      day_month_year: data?.plan_cycle,
      amount: data?.amount,
      feature: featureId,
    };
    // data?.feature.forEach((itm, index) => (

    // ))

    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_WMS_API_BASE_URL}/subscription_plan/${id}`,
        fdata
      );
      console.log(response);
      navigate("/Company/SubscriptionPlans");
      setDisableBtn(true);
    } catch (err) {
      console.log(err);
      setDisableBtn(false);
    }
  };

  return (
    <>
      <PrimaryTypography mb={3}>Edit Subscription Plan</PrimaryTypography>

      <Box
        mb={7}
        backgroundColor="#FFFFFF"
        p={2}
        sx={{ borderRadius: "8px", boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)" }}
      >
        <Grid container>
          <Grid sm={4} p={2}>
            <SecondaryTypography>Plan Name</SecondaryTypography>
            <TextField
              name="name"
              onChange={(e) => handleChange(e)}
              value={data?.name}
              placeholder="Please enter plan name"
              InputProps={{ disableUnderline: true }}
            />
            <ErrorTypography mt={1} fontSize=".9rem" color="error">
              {errText?.name}
            </ErrorTypography>
          </Grid>

          <Grid sm={4} p={2}>
            <SecondaryTypography>Duration</SecondaryTypography>
            <TextField
              name="duration"
              type="number"
              onChange={(e) => handleChange(e)}
              value={data?.duration}
              placeholder="Please enter duration"
              InputProps={{ disableUnderline: true }}
            />
            <ErrorTypography mt={1} fontSize=".9rem" color="error">
              {errText?.duration}
            </ErrorTypography>
          </Grid>
          <Grid sm={4} p={2}>
            <SecondaryTypography>Plan cycle</SecondaryTypography>

            <Select
              name="plan_cycle"
              value={data?.plan_cycle}
              onChange={(e) => handleChange(e)}
              sx={{ color: Boolean(data?.plan_cycle) ? "black" : "#AFB0B0" }}
              displayEmpty
              renderValue={
                data?.plan_cycle !== "" ? undefined : () => "Select plan cycle"
              }
            >
              {daymonthyearDD?.map((itm) => (
                <MenuItem value={itm?.id}>{itm?.name}</MenuItem>
              ))}
            </Select>
            <ErrorTypography mt={1} fontSize=".9rem" color="error">
              {errText?.plan_cycle}
            </ErrorTypography>
          </Grid>

          <Grid xs={12} p={2}>
            <SecondaryTypography>Plan Description</SecondaryTypography>
            <TextField
              sx={{ minHeight: "80px" }}
              name="description"
              onChange={(e) => handleChange(e)}
              value={data?.description}
              placeholder="Please enter description"
              InputProps={{ disableUnderline: true }}
              multiline
            />
            <ErrorTypography mt={1} fontSize=".9rem" color="error">
              {errText?.description}
            </ErrorTypography>
          </Grid>

          <Grid sm={4} p={2}>
            <SecondaryTypography>Feature</SecondaryTypography>
            <Autocomplete
              multiple
              options={featuresData}
              name="feature"
              value={data?.feature}
              onChange={(e, value) => handleChange(e, value)}
              getOptionLabel={(option) => {
                return <Typography>{option?.name}</Typography>;
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    sx={{ borderRadius: "5px" }}
                    variant="outlined"
                    label={option?.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                  placeholder="Select features"
                />
              )}
            />
          </Grid>
          <Grid sm={4} p={2}>
            <SecondaryTypography>Amount</SecondaryTypography>
            <TextField
              name="amount"
              type="number"
              onChange={(e) => handleChange(e)}
              value={data?.amount}
              placeholder="Please enter amount"
              InputProps={{ disableUnderline: true }}
            />
            <ErrorTypography mt={1} fontSize=".9rem" color="error">
              {errText?.amount}
            </ErrorTypography>
          </Grid>
        </Grid>
        <Stack my={2} alignItems="end">
          <Button variant="contained" onClick={submit} disabled={disableBtn}>
            Edit
          </Button>
        </Stack>
      </Box>
    </>
  );
}
