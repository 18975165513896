import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import LoginLayout from './Layout/LoginLayout';
import Login from '../Page/login';
// import List from './Page/Company/List'
// import Dashboard from './Page/Dashboard'
// import AddCompany from './Page/Company/AddCompany';
// import EditCompany from './Page/Company/EditCompany';
import { getToken, getUserData } from '../utils'
import { useSelector } from 'react-redux';
// import SubscriptionPlans from './Page/SubscriptionPlans';
// import EditSubscriptionPlan from './Page/SubscriptionPlans/EditSubscriptionPlan';
// import AddSubscriptionPlan from './Page/SubscriptionPlans/AddSubscriptionPlan';
import HitPay from '../Page/hitPay';
import Admin from './Admin';
import Customer from './Customer';

export default function Routing() {


    const [hasToken, setHasToken] = useState(false)
    const { loginStatus } = useSelector((state) => state.Login)


    useEffect(() => {
        if (localStorage.getItem('access_token') != null) {
            setHasToken(true)
        }
        else {
            setHasToken(false)
        }
    }, [loginStatus])


    console.log(getUserData('user_role'));

    return (
        <>

            {!hasToken ?
                <Routes>
                    <Route path="/" element={<Login />} />
                </Routes>
                :
                <>
                    {getUserData('user_role') == 1 ? <Admin /> : <Customer />}
                </>
            }

        </>
    )
}
