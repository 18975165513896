import React, { useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom';

export default function Success() {

    const navigate = useNavigate();
    // const { type, reference, status } = useParams();


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const reference = queryParams.get('reference');
    const status = queryParams.get('status');
    console.log(status, type, reference);


    useEffect(() => {
        if (type && reference && status) {

            console.log(`Type: ${type}, Reference: ${reference}, Status: ${status}`);
            if (status == 'active') {
                console.log('hgc');
                let data = JSON.parse(localStorage.getItem('company_details'))
                data.payment_status = 1
                localStorage.setItem("company_details", JSON.stringify(data))
                // navigate('/Dashboard');
                window.location.href = `${window.location.origin}/Dashboard`
                // window.location.href = response?.data?.data?.payment_request?.url
                // window.reload()

            }

        } else {
            console.error('Missing required parameters');
        }
    }, [type, reference, status, navigate]);


    return (
        <div>success</div>
    )
}
