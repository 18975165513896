import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Paper,
  Stack,
  Typography,
  Box,
  Button,
  PaginationItem,
  Pagination,
  OutlinedInput,
} from "@mui/material";
import {
  PrimaryTypography,
  secondaryTypography,
  StyledTableCell,
} from "../../Theme/theme";

import axiosInstance from "../../Service/apiservice";
import moment from "moment/moment";

const Invoice = () => {
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_WMS_API_BASE_URL}/payment/transaction?status=succeeded&page=${currentPage}`
      )
      .then((res) => {
        setData(res.data?.data?.transaction);
        setPaginationData(res.data?.data?.pagination_details);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [currentPage]);

  const handleInvoice = (id) => {
    const token = localStorage.getItem("access_token");
    if (id && token) {
      axiosInstance
        .get(
          `${process.env.REACT_APP_WMS_API_BASE_URL}/payment/get_invoice/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const filePath = res.data?.url;
          // Check if the filePath exists and is a secure URL (HTTPS)
          if (filePath && filePath.startsWith("https://")) {
            const newTab = window.open(filePath, "_blank");
            newTab?.focus();
          } else {
            console.error("Invalid or insecure file path:", filePath);
          }
        })
        .catch((err) => {
          // Check for specific HTTP error codes
          if (err.response) {
            console.error(
              "HTTP Error:",
              err.response.status,
              err.response.statusText
            );
          } else {
            console.error("Request failed:", err.message);
          }
        });
    }
  };

  return (
    <>
      <PrimaryTypography>Invoices</PrimaryTypography>
      <TableContainer
        sx={{ borderRadius: "12px", marginTop: "30px" }}
        component={Paper}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Invoice Id</StyledTableCell>
              <StyledTableCell>Issued Date</StyledTableCell>
              <StyledTableCell>Company Name</StyledTableCell>
              <StyledTableCell>Plan Name</StyledTableCell>
              <StyledTableCell>Amount(S$)</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Invoice</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((itm, index) => {
              return (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell>
                    {itm?.invoice_details?.uuid || "--"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {itm?.invoice_details?.created_at
                      ? moment(itm?.invoice_details?.created_at).format(
                          "DD MMM YYYY"
                        )
                      : "--"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {itm?.company_details?.company_name || "--"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {itm?.plan_details?.name || "--"}
                  </StyledTableCell>
                  <StyledTableCell>{itm?.amount || "--"}</StyledTableCell>
                  <StyledTableCell>{itm?.status}</StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleInvoice(itm?.id)}
                    >
                      Invoice
                    </Button>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #E5E7EB",
            borderRadius: "0px 0px 8px 8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            p: 2.5,
          }}
        >
          <Pagination
            page={currentPage}
            size="large"
            count={Math.ceil(paginationData?.total / paginationData?.per_page)}
            onChange={(e, value) => setCurrentPage(value)}
            renderItem={(item) => (
              <PaginationItem
                // slots={{ previous: PreviousIcon, next: NextIcon }}
                {...item}
              />
            )}
          />
        </Box>
      </TableContainer>
    </>
  );
};

export default Invoice;
