import { Chip } from "@mui/material"

export const getChip = (val) => {

    if (val == 'Requested') {
        return <Chip label={val} sx={{ color: 'black', border: '1px solid #d1d3d6', backgroundColor: '#eaecee!important', padding: '1px 5px', borderRadius: "20px" }} />
    }
    else if (val == 'In-progress') {
        return <Chip label={val} sx={{ color: '#007BFF', border: '1px solid #007BFF', backgroundColor: '#007bff1a !important', padding: '1px 5px', borderRadius: "20px" }} />
    }
    else if (val == 'Published') {
        return <Chip label={val} sx={{ color: '#f0b400', border: '1px solid #f0b400', backgroundColor: '#ffc1071f !important', padding: '1px 5px', borderRadius: "20px" }} />
    }
    else if (val == 'Active') {
        return <Chip label={val} sx={{ color: '#179935', border: '1px solid #28A745', backgroundColor: '#28a74521 !important', padding: '1px 5px', borderRadius: "20px" }} />
    }
    else if (val == 'Inactive') {
        return <Chip label={val} sx={{ color: '#d80015', border: '1px solid #DC3545', backgroundColor: '#dc354529 !important', padding: '1px 5px', borderRadius: "20px" }} />
    }
    else if (val == 'Rejected') {
        return <Chip label={val} sx={{ color: '#d80015', border: '1px solid #DC3545', backgroundColor: '#dc354529 !important', padding: '1px 5px', borderRadius: "20px" }} />
    }

}



export const getUserData = (val) => {
    const data = JSON.parse(localStorage.getItem('user_details'))
    if (data) {
        return data[val]
    }
}

export const getToken = () => {
    const data = localStorage.getItem('access_token')
    if (data) {
        return data
    }
}

export const getCompanyData = (val) => {
    const data = JSON.parse(localStorage.getItem('company_details'))
    if (data) {
        return data[val]
    }
}