import { Box, Button, Modal, IconButton, Typography, Stack } from "@mui/material";
import React from "react";
import { secondaryTypography, PrimaryTypography } from "../Theme/theme";
import Close from '@mui/icons-material/Clear';



const AlertModal = ({ modalDatas, confirmFunction, closeFunction }) => {
    let { label, descriptionOne, descriptionTwo, buttonOne, buttonTwo } = modalDatas
    return (
        <>
            <Modal open={true}>
                <Box sx={{ position: 'absolute', border: 'none', width: '100%', height: '100%', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ backgroundColor: '#FFFFFF', width: '40%', borderRadius: '8px', }}>
                        <Box sx={{ p: 2, borderBottom: '2px solid #F3F4F6', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <PrimaryTypography>{label}</PrimaryTypography>
                            <Close onClick={() => closeFunction()} />
                        </Box>
                        <Box sx={{ px: 2, py: 3, borderBottom: '2px solid #F3F4F6' }}>
                            <secondaryTypography>{descriptionOne}</secondaryTypography>
                            {descriptionTwo != '' && <secondaryTypography sx={{ mt: 3 }}>{descriptionTwo}</secondaryTypography>}
                        </Box>
                        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={() => closeFunction()} sx={{ background: buttonOne.backgroundColor, color: buttonOne.color }} variant={buttonOne.variant}>{buttonOne.text}</Button>
                            <Button onClick={() => confirmFunction()} sx={{ background: buttonTwo.backgroundColor, color: buttonTwo.color, "&:hover": { background: buttonTwo.backgroundColor } }} variant={buttonTwo.variant}>{buttonTwo.text}</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default AlertModal;


