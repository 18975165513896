import axios from 'axios';
import Helper from './helper';


const axiosInstance = axios.create({
    baseURL: "https://bsmartadmin.demopsts.com/api"
})

axiosInstance.interceptors.request.use((request) => {
    const token = localStorage.getItem('access_token')
    request.headers.Authorization = `Bearer ${token}`
    return request
},

    (err) => {
        Promise.reject(err)
    })


export default axiosInstance;