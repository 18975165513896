import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { Box, InputAdornment, IconButton } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { secondaryTypography, ErrorTypography } from '../Theme/theme';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axiosInstance from '../Service/apiservice'
import { Navigate } from 'react-router-dom';
import logo from '../Image/logo.png';
import { login } from "../Redux/Feature/Login/LoginSlice"

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Login() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [data, setData] = useState({ email: '', password: "" })
  const [showPassword, setShowPassword] = useState(false);
  const [errtext, setErrtext] = useState({ email: '', password: '' })
  const { loginStatus } = useSelector((state) => state.Login)
  const [resErr, setResErr] = useState(null)


  useEffect(() => {

    if (loginStatus != null) {
      if (loginStatus.success) {
        if (loginStatus?.data?.user_details?.user_role == 1) {
          localStorage.clear()
          localStorage.setItem("access_token", loginStatus?.data?.token?.access_token)
          localStorage.setItem("user_details", JSON.stringify(loginStatus?.data?.user_details))
          navigate('/Company')
        }
        else if (loginStatus?.data?.user_details?.user_role == 2) {
          localStorage.clear()
          localStorage.setItem("access_token", loginStatus?.data?.token?.access_token)
          localStorage.setItem("user_details", JSON.stringify(loginStatus?.data?.user_details))
          localStorage.setItem("company_details", JSON.stringify(loginStatus?.data?.copamay_details))
          if (loginStatus?.data?.copamay_details?.payment_status == 0) {
            navigate('/pay')
          }
          else if (loginStatus?.data?.copamay_details?.payment_status == 1) {
            navigate("/Dashboard")
          }
        }
      }
      else {
        setResErr(loginStatus?.error)
      }
    }

  }, [loginStatus])


  useEffect(() => {
    setResErr(null)
  }, [data])

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const valid = () => {
    let val = true
    if (data?.email == '') {
      setErrtext((prev) => ({ ...prev, email: "please enter email" }))
      val = false
    }
    if (data?.password == '') {
      setErrtext((prev) => ({ ...prev, password: "please enter password" }))
      val = false
    }
    return val
  }


  const loginApi = () => {
    const values = new FormData()
    values.append('email', data?.email)
    values.append('password', data?.password)

    dispatch(login(values))

  }

  const handleSubmit = () => {
    if (valid()) {
      loginApi()
    }
  };



  return (
    <>    <Box sx={{
      display: 'flex',
      justifyContent: "center",
      height: '100vh',
      width: "100%",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      // backgroundImage: "linear-gradient(135deg, rgba(0, 223, 172, 1) 0%, rgba(0, 69, 159, 1) 78%)",
      backgroundImage: "Url(https://images.pexels.com/photos/7947841/pexels-photo-7947841.jpeg?auto=compress&cs=tinysrgb&w=600)",
      backgroundRepeat: "no-repeat",
      filter: "blur(6px)",
    }}>

    </Box>
      <Box padding="20px 30px 30px 30px" mx={5}
        sx={{
          width: { sx: '60%', md: '40%', lg: '24%' },
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          height: 'fit-content',
          borderRadius: '8px',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'rgb(0,0,0)',
          backgroundColor: 'rgba(0,0,0, 0.4)',
          color: 'white',
          border: '1px solid #5AC3CC'

        }}
      >
        <Box borderBottom="1px solid #0bc3c8" mb={2}>
          <img src={logo} width="200px" height="100%" objectFit='cover' sx={{ borderBottom: '1px solid black' }} />
        </Box>
        {/* <Avatar sx={{ m: 1, bgcolor: '#5AC3CC' }}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5" my={2} sx={{ textAlign: 'left' }}>
          LOGIN
        </Typography>
        <Box width='100%' noValidate sx={{ textAlign: 'left' }}>

          <TextField
            fullWidth
            placeholder="Email Address"
            name="email"
            onChange={(e) => { setData((prev) => ({ ...prev, "email": e.target.value })); setErrtext((prev) => ({ ...prev, email: "" })) }}
            autoComplete="email"
            InputProps={{ disableUnderline: true }}
          />
          <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errtext?.email}</ErrorTypography>

          <TextField sx={{ paddingRight: '20px' }}
            fullWidth
            name="password"
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => { setData((prev) => ({ ...prev, "password": e.target.value })); setErrtext((prev) => ({ ...prev, password: "" })) }}
            InputProps={{
              disableUnderline: true, endAdornment: (
                < InputAdornment position="end" >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff sx={{ fontSize: "1.3rem" }} /> : <Visibility sx={{ fontSize: "1.3rem" }} />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <ErrorTypography mt={1} fontSize='.9rem' color='error'>{errtext?.password}</ErrorTypography>
          <ErrorTypography mt={1} fontSize='1rem !important' color='error'>{resErr}</ErrorTypography>

          <Button
            onClick={() => handleSubmit()}
            fullWidth
            sx={{ mt: 3, mb: 2, backgroundColor: '#5AC3CC', color: 'white', '&:hover': { backgroundColor: '#54b0b7 !important' } }}
          >
            Sign In
          </Button>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          {/* <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid> */}
        </Box>
      </Box>

    </>

  );
}